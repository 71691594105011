/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'gatsby';

import ArrowIconPath from '../images/icons/arrowRight.png'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 246px;
  flex-basis: 1;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-top-width: 5px;
  border-top-style: solid;
  border-top-color: #FF9805;
  margin-top: 46px;
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0 25px 21px;
  flex-basis: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.870588);
`;

const Author = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.45);
`;

const DateButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  margin-top: auto;
`;

const Date = styled(Author)`
  justify-self: flex-end;
  margin-top: auto;
`;

const PreviewImage = styled.img`
  height: 246px;
  width: 246px;
  flex-basis: 245px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const ReadButton = styled(Link)`
  border-width: 1.5px;
  border-style: solid;
  border-color: #FF9805;
  color: #FF9805;
  border-radius: 4px;
  width: 93px;
  height: 35px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  justify-self: flex-end;
  text-transform: uppercase;
  user-select: none;
  text-decoration: none;
  margin: 0 21px auto auto;
`;

const ArrowIcon = styled.img`
  width: 15px;
  height: 10px;
`;

const PostPreview = ({
  title,
  author,
  date,
  thumbnailPath,
  slug,
}) => (
  <Container>
    <PreviewImage src={thumbnailPath} />
    <MetaContainer>
      <Title>
        {title}
      </Title>
      <Author>
        by {author}
        </Author>
      <DateButtonRow>
        <Date>
          {moment(date).format('Do MMMM YYYY')}
        </Date>
        <ReadButton to={slug}>
          Read
          <ArrowIcon src={ArrowIconPath} />
        </ReadButton>
      </DateButtonRow>
    </MetaContainer>
  </Container>
  );

PostPreview.PropTypes = {
  name: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.date,
  thumbnailPath: PropTypes.string,
};

PostPreview.DefaultProps = {
  name: '',
  author: '',
  date: 1,
  thumbnailPath: '',
};

export default PostPreview;
