/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import WidthWrapper from '../components/WidthWrapper';
import Heading from '../components/Heading';
import SubHeading from '../components/SubHeading';
import Navigation from '../components/Navigation';
import Footer from '../sections/Footer';
import faviconPath from '../images/favicon.ico';
import PostPreview from '../components/PostPreview';

const Container = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
`;

const Articles = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 43px;
`;

const FooterWrapper = styled.div`
  margin-top: 100px;
`;


const Learn = ({ data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Learn - Bidmatik</title>
        <link rel="canonical" href="https://bidmatik.com/aboutus" />
        <link rel="shortcut icon" type="image/png" href={faviconPath} />
      </Helmet>
      <Navigation />
      <WidthWrapper>
        <Heading>
          Learn
        </Heading>
        <SubHeading>
          Amazon Advertising
        </SubHeading>
        <Articles>
          {posts.map(({ node: post }) => {
            const thumbnailPath = post.frontmatter.thumbnail.childImageSharp.resolutions.src;
            return (
              <PostPreview
                description={post.frontmatter.description}
                title={post.frontmatter.title}
                author={post.frontmatter.author}
                date={post.frontmatter.date}
                slug={post.fields.slug}
                thumbnailPath={thumbnailPath}
              />
            );
          })}
          {/* <PostPreview name="Amazon Advertising 101" author="Thomas Hamilton" date={Date.now()} articleUri="https://googe.com" imageUri="" /> */}
        </Articles>
      </WidthWrapper>
      <FooterWrapper>
        <Footer showBackground />
      </FooterWrapper>
    </Container>
  );
};

Learn.propTypes = {
  data: PropTypes.shape({
    edges: PropTypes.array,
  }),
};

Learn.defaultProps = {
  data: {
    edges: [{
      node: {
        frontmatter: {
          title: 'Not Found',
        },
      },
    }],
  },
};

export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title,
            author,
            date,
            thumbnail {
              childImageSharp {
                resolutions(width: 245) {
                  width
                  height
                  src
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Learn;
