import styled from 'styled-components';

export default styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: rgba(0, 0, 0, 0.870588);
  user-select: none;
  margin-top: 127px;
`;
